import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import classnames from 'classnames';

import { links, routes, mediaQuery } from '../../constants';

import logoWide from '../../../assets/images/logo/logo-wide.svg';
import logoWideDm from '../../../assets/images/logo/logo-wide.dm.svg';
import logoMin from '../../../assets/images/logo/logo-min.svg';

import { Button, MenuButton, ButtonCta } from '../button';

const HEADER_LINKS = [
    {
        title: 'Home',
        url: routes.HOMEPAGE,
    },
    {
        title: 'Plans',
        url: routes.PLANS,
    },
    {
        title: 'How you pay',
        url: routes.PAY,
    },
    {
        title: 'Reviews',
        url: routes.REVIEWS,
    },
    {
        title: 'Claims',
        url: links.CLAIMS,
    },
    {
        title: 'About us',
        url: routes.ABOUT,
    },
];

export const Header = () => {
    const [navVisible, setNavVisible] = useState(false);

    return (
        <header className="c-header" role="banner">
            <nav className="c-header__inner l-container" role="navigation" aria-labelledby="menu">
                <a className="c-header__logo" href={routes.HOMEPAGE}>
                    <picture className="u-block">
                        <source
                            srcSet={logoWideDm}
                            media={`${mediaQuery.MIN_MEDIUM} and (prefers-color-scheme: dark)`}
                            width={200}
                            height={30}
                        />
                        <source
                            srcSet={logoWide}
                            media={`${mediaQuery.MIN_MEDIUM} and (prefers-color-scheme: light)`}
                            width={200}
                            height={30}
                        />
                        <source
                            srcSet={logoMin}
                            media={mediaQuery.MAX_MEDIUM}
                            width={40}
                            height={30}
                        />
                        <img src={logoMin} alt="Hugo Insurance" width={40} height={30} />
                    </picture>
                </a>

                <div
                    aria-hidden="true"
                    className={classnames('c-header__overlay', {
                        'is-visible': navVisible,
                    })}
                    onClick={() => setNavVisible(false)}
                    tabIndex={-1}
                />

                <div
                    className={classnames('c-header__navbar', {
                        'is-open': navVisible,
                    })}
                    id="main-nav"
                >
                    {HEADER_LINKS.map((link) => (
                        <Link
                            activeClassName="is-active"
                            class="c-header__nav-link"
                            href={link.url}
                            key={link.url}
                            onClick={() => setNavVisible(false)}
                        >
                            {link.title}
                        </Link>
                    ))}

                    <div className="c-header__menu-actions">
                        <Button
                            block
                            className="sm:u-font-4 u-py-3 u-mb-4"
                            href={links.HUGO_APP}
                            outline
                            primary
                        >
                            Log in
                        </Button>

                        <ButtonCta
                            block
                            className="track:lp__cta--other sm:u-font-4 u-p-3"
                            header
                            href={links.QUOTE_FLOW}
                        >
                            Check my price
                        </ButtonCta>
                    </div>
                </div>

                {HEADER_LINKS.map((link) => (
                    <Link
                        activeClassName="is-active"
                        class="c-header__link"
                        href={link.url}
                        key={link.url}
                    >
                        {link.title}
                    </Link>
                ))}

                <ButtonCta
                    header
                    className="c-header__navbar-btn c-header__cta track:lp__cta--floating"
                    href={links.QUOTE_FLOW}
                >
                    Get started
                </ButtonCta>

                <Button
                    className="c-header__navbar-btn c-header__acc"
                    href={links.HUGO_APP}
                    outline
                    primary
                >
                    Log in
                </Button>

                <MenuButton
                    aria-controls="main-nav"
                    aria-label="Toggle main navigation"
                    className="c-header__navbar-btn c-header__menu"
                    onClick={() => setNavVisible(!navVisible)}
                    open={navVisible}
                />
            </nav>
        </header>
    );
};
